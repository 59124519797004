import { Col, Offcanvas, Row} from "react-bootstrap";
import {Dispatch} from "react";
import { IoHomeOutline } from "react-icons/io5";
import { IoQrCodeOutline } from "react-icons/io5";
import { IoIosLink } from "react-icons/io";
import { IoPersonCircleSharp } from "react-icons/io5";
import { FaMoneyCheck } from "react-icons/fa";
import { IoIosPower } from "react-icons/io";
import {useAuthData} from "../../../context/AuthContext";
import {Link} from "react-router-dom";
import {FiPower} from "react-icons/fi";

export type MobileOffCanvasProps = {
    showState:boolean
    setShowState:Dispatch<boolean>
}

function MobileOffCanvas({showState, setShowState}:MobileOffCanvasProps){
    const {logout} = useAuthData()

    return (
        <Offcanvas show={showState} onHide={() => setShowState(!showState)} placement={"bottom"} style={{height: "70%"}} className="bg-grey">
            <Offcanvas.Header closeButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="d-flex align-content-between justify-content-start  h-100">
                    <div className="d-flex flex-column w-100">
                        <Row className="mt-5 w-100 border-bottom border-2">
                            <Col className="w-100">
                                <div className="d-flex justify-content-start align-items-center">
                                    <IoHomeOutline className="me-3" size={20}/>
                                    <Link to={"/"} className="fs-5 text-black fw-bold">Home</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 w-100 border-bottom border-2">
                            <Col className="w-100">
                                <div className="d-flex justify-content-start align-items-center">
                                    <FiPower className={"me-3"} size={25} />
                                    <Link to={"/connect-page"} className="fs-5 text-black fw-bold">Connect Page</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 border-bottom border-2 w-100">
                            <Col className=" w-100">
                                <div className="d-flex justify-content-start align-items-center">
                                    <IoPersonCircleSharp className="me-3" size={20}/>
                                    <Link to={"/profile"} className="fs-5 text-black fw-bold">Profile</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 border-bottom border-2 w-100">
                            <Col className=" w-100">
                                <div className="d-flex justify-content-start align-items-center">
                                    <FaMoneyCheck className="me-3" size={20}/>
                                    <Link to={`${import.meta.env.VITE_PUBLIC_STRIPE_CUSTOMER_PORTAL_LINK}`} className="fs-5 text-black fw-bold">Subscription</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 w-100 border-bottom border-2">
                            <Col className="w-100">
                                <div className="d-flex justify-content-start align-items-center">
                                    <IoIosLink className="me-3" size={20}/>
                                    <Link to={"/links"} className="fs-5 text-black fw-bold">Links</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 border-bottom border-2 w-100">
                            <Col className="w-100">
                                <div className="d-flex justify-content-start align-items-center">
                                    <IoQrCodeOutline className="me-3" size={20}/>
                                    <Link to={"/qr-codes"} className="fs-5 text-black fw-bold">QR Codes</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 w-100 border-bottom border-2">
                            <Col className=" w-100" as={"button"} onClick={() => logout()} >
                                <div className="d-flex justify-content-start align-items-center w-100">
                                    {/*<IoIosPower className="me-3" size={20}/>*/}
                                    <h2 className="fs-5 fw-bold">Logout</h2>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default MobileOffCanvas