import {createContext, Dispatch, PropsWithChildren, useContext, useEffect, useState} from "react";
import {RedAccount, RedUser} from "../types/AuthTypes";
import {useLocalStorage} from "../library/hooks/useLocalStorage";
import {APICore} from "../library/utilities/apiCore.js";
import {useSearchParams} from "react-router-dom";
import {ConnectProvider} from "./AppDataContext";
import {signIn, signOut, getCurrentUser, fetchAuthSession,fetchUserAttributes} from "aws-amplify/auth";
import 'aws-amplify/auth/enable-oauth-listener';
import { Hub } from 'aws-amplify/utils';
import {useLocation, useNavigate} from "react-router";
import {unprotectedRoutes} from "../library/constants/unprotectedRoutes";


interface AuthContext {
    user: RedUser | null;
    setUser: Dispatch<RedUser | null> | null
    login: (userName: string, password: string) => Promise<void> | null
    logout: () => Promise<void> | null
    isLoading: boolean;
    isAuthenticated: boolean;
    err: number
    account: RedAccount | null
    setAccount: Dispatch<RedAccount | null> | null
    addAccount: (account: RedAccount) => void
    addUser: (user: RedUser) => void
    checkCognitoSession: (() => Promise<boolean>) | null
    //location:Location
    routeIsUnprotected: () => boolean|undefined
}


export const AuthContext = createContext<AuthContext>({
        err: 0,
        user: null,
        setUser: null,
        //@ts-ignore
        login: null,
        //@ts-ignore
        logout: null,
        status: "unauthenticated",
        setStatus:null,
        account: null,
        setAccount: null,
        isLoading: false,
        isAuthenticated: false,
        addAccount:() => {},
        addUser: () => {},
        checkCognitoSession: null
    }
);

const useAuthData = () => {
    const data = useContext(AuthContext)
    if(!data){
        throw new Error("useAuthData must be called in AuthProvider")
    }
    return data
}

type RovaProviderProps = {
    children: PropsWithChildren<any>;
}

const RovaProvider = ({children}:RovaProviderProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState<RedUser|null>(null)
    const [account, setAccount] = useState<null|RedAccount>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const { setItem, getItem } = useLocalStorage();
    const [searchParams] = useSearchParams()
    const [err, setErr] = useState<number>(0)
    console.log("location: ",location)

    const parseErrParam = () => {
        if(window.location && searchParams){
            try{
                setErr(Number(searchParams.get("err")))
            }catch (e){
            }
        }
    }

    const addUser = (user: RedUser) => {
        setUser(user);
        setItem("user", JSON.stringify(user));
    };

    const addAccount = (account:RedAccount) => {
        setAccount(account)
        setItem("account", JSON.stringify(account))
    }
    const checkCognitoSession = async () => {
        try{
            const session = await fetchAuthSession()
            // console.log("session: ",session)
            // console.log("loading context: ",isLoading)
            // const res = await getCurrentUser()
            // console.log("res: ",res)
            setIsLoading(false)
            setIsAuthenticated(true)
            return true
        }catch(e){
            removeUser();
            setIsLoading(false)
            setIsAuthenticated(false)
            return false
        }

    }
    const refreshSession = async () => {
        try {
            const {tokens}  = await fetchAuthSession({forceRefresh:true});
            setIsLoading(false)
            setIsAuthenticated(true)
            return true

        } catch (error) {
            console.error('Error refreshing tokens:', error);
            removeUser()
            setIsLoading(false)
            setIsAuthenticated(false)
            return false
        }
    };

    const routeIsUnprotected = () => {
        let returnFlag = false
        if(location && location.pathname){
            unprotectedRoutes.forEach((route) => {
                if(location.pathname.includes(route)){
                    console.log("setting return flag true")
                    returnFlag = true
                }
            })
            console.log("returnFlag: ",returnFlag)
            return returnFlag
        }else{
            //dont redirect if location not yet loaded
            console.log("default returnng true")
            return true
        }
    }

    useEffect(() => {
        //@ts-ignore
        const validSession = checkCognitoSession().catch(console.error)
        const storedUser = getItem("user");
        const storedAccount = getItem("account")
        //@ts-ignore
        if (storedUser && storedAccount && validSession) {
            addUser(JSON.parse(storedUser));
            addAccount(JSON.parse(storedAccount))
        }else{
           if(!routeIsUnprotected()){
               console.log("context navigated to logout")
               navigate("/logout")
           }
        }


        parseErrParam()
    }, []);

    const removeUser = () => {
        setUser(null);
        setItem("user", "");

        setAccount(null)
        setItem("account", "")
    };


    const login = async (userName:string, password:string) => {
        try {
            const res = await signIn({username:userName, password:password});
            setIsAuthenticated(true);

            //get user and account
            const userData = await new APICore().get("/getme")
            const retrievedUser = userData.data.user as RedUser
            const retrievedAccount = userData.data.account as RedAccount

            addUser(retrievedUser)
            addAccount(retrievedAccount)
            //@ts-ignore
            window.location = "/"
        } catch (error) {
            console.error(error)
            //@ts-ignore
            window.location = `/login?err=${err + 1}`
        }
    };

    const logout = async () => {
        try{
            const res = await signOut()
            removeUser()
        }catch(e){
            console.error(e)

        }
        //@ts-ignore
        window.location = "/logout"
    };

    const getMeCall = async (attemptNumber: number) => {
        const newAttemptNumber = attemptNumber+1
        try{
            //  user = await getCurrentUser();
            //  userAttributes = await fetchUserAttributes();
            //  console.log("oauth payload: ",payload)
            // console.log({user, userAttributes});
            //get user and account
            if(newAttemptNumber < 4){
                const userData = await new APICore().get("/getme")
                console.log("userData res: ",userData)
                const retrievedUser = userData.data.user as RedUser
                const retrievedAccount = userData.data.account as RedAccount

                addUser(retrievedUser)
                addAccount(retrievedAccount)
                //@ts-ignore
                window.location = "/"
            }else{
                throw Error("unable to perform get me call after 3 attempts")
            }
        }catch(e){
            console.error(e)
            await getMeCall(newAttemptNumber)
        }
    }

    //need to make Interval heartbeat to Rova to check user status

    //mabe a refresh function export here?? could do hook?

    Hub.listen("auth", async ({ payload }) => {
        // let user;
        // let userAttributes;
        // let userData;
        // let retrievedUser;
        // let retrievedAccount;
        switch (payload.event) {
            case "signInWithRedirect":
                await getMeCall(0)
                break;
            case "signInWithRedirect_failure":
                console.log("sign in redirect failure event")
                // handle sign in failure
                break;
            case "customOAuthState":
                // const state = payload.data; // this will be customState provided on signInWithRedirect function
                // console.log(state);
                break;
        }
    });


    return (
        <AuthContext.Provider
            value={{user,setUser, login, logout,err, account, setAccount, isAuthenticated, isLoading,addAccount,addUser,checkCognitoSession,routeIsUnprotected }}
        >
            <ConnectProvider>
                {children}
            </ConnectProvider>
        </AuthContext.Provider>
    )


}

//??? might cause problem wel see???//
export { useAuthData,RovaProvider}

