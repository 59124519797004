import {Link} from "react-router-dom";
import {Col, Row} from "react-bootstrap";


function Footer(){


    return (
        <div className="footer">
            <div className="w-full d-md-flex justify-content-end m-4 rounded-2 d-none" style={{minHeight: "80px"}}>
                <div className="d-flex justify-content-end align-items-end">
                    <Row>
                        <Col>
                            <div className=" d-flex align-items-center mx-2 h-100">
                                <Link to={"https://www.hatchery.com"} target="_blank" className="text-hd text-nowrap">&copy;&nbsp;Hatchery LLC<span className="ms-2">&nbsp;{new Date().getFullYear()}</span></Link>
                            </div>
                        </Col>
                        <Col>
                            <div className=" d-flex align-items-center mx-2 h-100">
                                <Link to={"https://www.hatchery.com/terms"} target={"_blank"} className="text-hd text-nowrap" >Terms of Service</Link>
                            </div>
                        </Col>
                        <Col>
                            <div className=" d-flex align-items-center mx-2 h-100">
                                <Link to={"https://www.hatchery.com/privacy"} target={"_blank"} className="text-hd text-nowrap">Privacy Policy</Link>
                            </div>
                        </Col>
                    </Row>


                </div>
            </div>

        </div>
    )
}

export default Footer