import {useEffect, useState} from "react";
import {useAuthData} from "../../../context/AuthContext";
import {useAppData} from "../../../context/AppDataContext";
import {ProgressBar} from "react-bootstrap";
import React from "react"


function LinkMonthlyUsage(){
    const [linkCap,setLinkCap] = useState<number>(0)
    const {account} = useAuthData()
    const {monthlyUsage,subscriptionRole,accountLinks} = useAppData()

    const findLinkCap = () => {
        if(account && subscriptionRole){
           setLinkCap(subscriptionRole.linkCap)
        }
    }

    const calcUsage = () => {
        if(account && monthlyUsage && accountLinks && subscriptionRole){
            if(subscriptionRole.role !== "no_subscription"){
                return Math.round((monthlyUsage.linkUsage/linkCap)*100)
            }else{
                return Math.round((accountLinks.length/linkCap)*100)
            }
        }
    }

    useEffect(() => {
        findLinkCap()
    },[account,subscriptionRole])

    return (
        <>
            {account && subscriptionRole && subscriptionRole.role !== "no_subscription" && monthlyUsage && (
                <div className="w-75 text-black">
                    <p className="fs-5">{`Links: ${monthlyUsage.linkUsage}/${linkCap}`}</p>
                    <ProgressBar now={calcUsage()} variant={"secondary"}/>
                </div>
            )}
            {account && accountLinks && subscriptionRole && subscriptionRole.role === "no_subscription" && (
                <div className="w-75 text-black">
                    <p className="fs-5">{`Links: ${accountLinks.length}/${linkCap}`}</p>
                    <ProgressBar now={calcUsage()} variant={"secondary"}/>
                </div>
            )}
        </>
    )
}

export default LinkMonthlyUsage