import {
    ApiKey,
    ConnectPage, ConnectPageSummaryReport,
    MonthlyUsage,
    QrCodeObject,
    RedFile,
    SubscriptionRole,
    UrlDocument
} from "../types/AppObjectTypes";
import {createContext, Dispatch, PropsWithChildren, useContext, useEffect, useState} from "react";
import {
    getAccountApiKeys,
    getAccountLinks,
    getAccountQrCodes,
    getAccountRedFiles,
    getMonthlyUsage,
    getAccountConnectPage, getConnectPageSummaryReport
} from "../services/ConnectServices";
import {useAuthData} from "./AuthContext";
import {SubscriptionRoles} from "../library/constants/subscriptionRoles";

export interface AppDataContext{
    accountLinks:UrlDocument[]|[]
    setAccountLinks:Dispatch<UrlDocument[]|[]>
    fetchAccountLinks:() => void| Promise<void>
    accountQrCodes:QrCodeObject[]|null
    setAccountQrCodes:Dispatch<QrCodeObject[]|null>
    fetchAccountQrCodes:() => void|Promise<void>
    accountRedFiles:RedFile[]|[]
    setAccountRedFiles:Dispatch<RedFile[]|[]>
    fetchAccountRedFiles:() => void|Promise<void>
    fetchAccountApiKeys: () => void|Promise<void>
    setAccountApiKeys:Dispatch<ApiKey[]|[]>
    accountApiKeys:ApiKey[]|[]
    monthlyUsage:MonthlyUsage|null
    setMonthlyUsage:Dispatch<MonthlyUsage|null>
    fetchMonthlyUsage:() => void|Promise<void>
    subscriptionRole:SubscriptionRole|null
    setSubscriptionRole: Dispatch<null|SubscriptionRole>
    accountConnectPage:ConnectPage|null
    setAccountConnectPage:Dispatch<ConnectPage|null>
    accountConnectPageSummary:ConnectPageSummaryReport|null
    setAccountConnectPageSummary:Dispatch<ConnectPageSummaryReport|null>
}

export const AppDataContext = createContext<AppDataContext|null>(null)

const useAppData = () => {
    const data = useContext(AppDataContext)
    if(!data){
        throw new Error("useAuthData must be called in App data context")
    }
    return data
}

type ConnectProviderProps = {
    children: PropsWithChildren<any>;
}

const ConnectProvider = ({children}:ConnectProviderProps) => {
    const [accountLinks, setAccountLinks] = useState<UrlDocument[]|[]>([])
    const [accountQrCodes, setAccountQrCodes] = useState<QrCodeObject[]|null>(null)
    const [accountRedFiles, setAccountRedFiles] = useState<RedFile[]|[]>([])
    const [accountApiKeys, setAccountApiKeys] = useState<ApiKey[]|[]>([])
    const [monthlyUsage, setMonthlyUsage] = useState<MonthlyUsage|null>(null)
    const [subscriptionRole, setSubscriptionRole] = useState<SubscriptionRole|null>(null)
    const [accountConnectPage, setAccountConnectPage] = useState<ConnectPage|null>(null)
    const [accountConnectPageSummary, setAccountConnectPageSummary] = useState<ConnectPageSummaryReport|null>(null)
    const {user,account,isAuthenticated} = useAuthData()

    const fetchAccountLinks = async () => {
        if(user && isAuthenticated){
            const links = await getAccountLinks(user.accountKey)
            setAccountLinks(links)
        }
    }
    const fetchAccountQrCodes = async () => {
        if(user && isAuthenticated){
            const qrCodes = await getAccountQrCodes(user.accountKey)
            setAccountQrCodes(qrCodes)
        }
    }

    const fetchAccountRedFiles = async () => {
        if(user && isAuthenticated){
            const rFiles = await getAccountRedFiles()
            setAccountRedFiles(rFiles)
        }
    }

    const fetchAccountApiKeys = async () => {
        if(user && isAuthenticated){
            const keys = await getAccountApiKeys()
            setAccountApiKeys(keys)
        }
    }

    const fetchMonthlyUsage = async () => {
        if(user && isAuthenticated){
            const monthly = await getMonthlyUsage()
            setMonthlyUsage(monthly)
        }
    }

    const getSubscriptionRole = () => {
        if(account && isAuthenticated){
           const subscriptionRoleFilter = SubscriptionRoles.filter(s => s.role === account.subscriptionRole)
            if(subscriptionRoleFilter.length > 0){
                const subRole = subscriptionRoleFilter[0]
                setSubscriptionRole(subRole)
            }else{
                console.log("error matching user subscription to role")
            }
        }
    }

    const fetchAccountConnectPage = async () => {
        if(user && isAuthenticated){
            const page = await getAccountConnectPage()
            setAccountConnectPage(page)
        }
    }

    const fetchAccountConnectPageSummary = async () => {
        try{
            if(user && isAuthenticated && accountConnectPage && Object.entries(accountConnectPage).length !== 0){
                const res = await getConnectPageSummaryReport()
                if(res.status === 200){
                    const report = res.data
                    setAccountConnectPageSummary(report)
                }
            }
        }catch(e){
            console.error(e)
        }
    }

//curious about promise.all does it effect pererformance by eliminating waterfall??
    const fetchAll = () => {
        fetchAccountLinks().catch(console.error)
        fetchAccountQrCodes().catch(console.error)
        fetchAccountRedFiles().catch(console.error)
        fetchAccountApiKeys().catch(console.error)
        fetchMonthlyUsage().catch(console.error)
        fetchAccountConnectPage().catch(console.error)
    }
    useEffect(() => {
        fetchAll()
    },[user,isAuthenticated])

    useEffect(() => {
        getSubscriptionRole()
    },[account,isAuthenticated])

    useEffect(() => {
        fetchAccountConnectPageSummary().catch(console.error)
    },[accountConnectPage])

    return (
        <AppDataContext.Provider
            value={
                {
                    accountLinks,
                    setAccountLinks,
                    fetchAccountLinks,
                    accountQrCodes,
                    setAccountQrCodes,
                    fetchAccountQrCodes,
                    accountRedFiles,
                    setAccountRedFiles,
                    fetchAccountRedFiles,
                    accountApiKeys,
                    setAccountApiKeys,
                    fetchAccountApiKeys,
                    monthlyUsage,
                    setMonthlyUsage,
                    fetchMonthlyUsage,
                    subscriptionRole,
                    setSubscriptionRole,
                    accountConnectPage,
                    setAccountConnectPage,
                    accountConnectPageSummary,
                    setAccountConnectPageSummary
                }
            }
        >
            {children}
        </AppDataContext.Provider>
    )

}
export {useAppData, ConnectProvider}